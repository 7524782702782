const BASE_PROXY_URL = '/api'; // URL вашего backend-сервера
// const BASE_PROXY_URL = 'http://localhost:5000/api'; // URL вашего backend-сервера
// Определяем функцию handleResponse
const handleResponse = (res) => {
  if (res.ok) {
    return res.json();
  }
  return Promise.reject(res);
};

export function authorize({ username, password }) {
  return fetch(`${BASE_PROXY_URL}/authorize?username=${username}&password=${password}`, {
    method: 'GET',
  }).then(handleResponse);
}

export function userId({ username }) {
  return fetch(`${BASE_PROXY_URL}/userId?username=${username}`, {
    method: 'GET',
  }).then(handleResponse);
}

export function balance({ userId }) {
  return fetch(`${BASE_PROXY_URL}/balance?userId=${userId}`, {
    method: 'GET',
  }).then(handleResponse);
}

export function availableTime() {
  return fetch(`${BASE_PROXY_URL}/availableTime`, {
    method: 'GET',
  }).then(handleResponse);
}

export function availableTimeOnPc({ userId }) {
  return fetch(`${BASE_PROXY_URL}/availableTimeOnPc?userId=${userId}`, {
    method: 'GET',
  }).then(handleResponse);
}

export function booking(bookingData) {
  return fetch(`${BASE_PROXY_URL}/booking`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json',
    },
    body: JSON.stringify(bookingData) // Отправка данных напрямую
  })
  .then(handleResponse)
  .catch((error) => {
    console.error('Ошибка при выполнении запроса на бронирование:', error);
    throw error; // Бросаем ошибку дальше, чтобы её можно было поймать в другом месте
  });
}

export function telegram(bookingDataForTG) {
  return fetch(`${BASE_PROXY_URL}/telegram`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ bookingDataForTG: bookingDataForTG }) // Убедитесь, что bookingDataForTG — это строка
  }).then(handleResponse);
}
