import React, { useState, useEffect } from 'react';

const BASE_PROXY_URL = '/api'; // URL вашего backend-сервера
// const BASE_PROXY_URL = 'http://localhost:5000/api'; // URL вашего backend-сервера

export function FreeNight({ userId }) {
    const [freeNights, setFreeNights] = useState([]); // Список бесплатных ночей
    const [message, setMessage] = useState(''); // Сообщение о статусе
    const [errorMessage, setErrorMessage] = useState(''); // Для отображения ошибок
    const [loading, setLoading] = useState(true); // Для отображения состояния загрузки

    useEffect(() => {
        const fetchFreeNightInfo = async () => {
            try {
                // Отправляем запрос на сервер для проверки бесплатных ночей
                const response = await fetch(`${BASE_PROXY_URL}/checkFreeNight?userId=${userId}`);

                // Проверяем тип содержимого
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    const text = await response.text();  // Получаем ответ как текст
                    throw new Error(`Некорректный ответ сервера: ${text}`);
                }

                const data = await response.json();
                if (response.ok) {
                    setMessage(data.message); // Устанавливаем сообщение
                    setFreeNights(data.freeNights); // Сохраняем список бесплатных ночей
                } else {
                    setErrorMessage(data.error || 'Ошибка при получении данных о ночах.');
                }
            } catch (error) {
                console.error('Ошибка при получении информации о ночах:', error);
                setErrorMessage('Ошибка при подключении к серверу.');
            } finally {
                setLoading(false); // Завершаем загрузку
            }
        };

        fetchFreeNightInfo();  // Вызываем функцию для получения информации о ночах
    }, [userId]);

    if (loading) {
        return <p>Загрузка информации о бесплатных ночах...</p>;
    }

    return (
        <div className="free-night-box">
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {!errorMessage && (
                <>
                    <p>{message}</p>
                    {freeNights.length > 0 && (
                        <ul>
                            {freeNights.map((night, index) => (
                                <li key={index}>{night}</li> // Выводим каждую бесплатную ночь
                            ))}
                        </ul>
                    )}
                    <p>(Функция в режиме теста может выдавать Некорректный ответ, уточняйте у администратора)</p>
                </>
            )}
        </div>
    );
}